.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 50px;
    height: 25px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .1s;
    // background-color: green;
    // right: 3px;
  }

  .switch-after {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 50px;
    height: 25px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .1s;
    background-color: #32b132;
  }
  
  .react-switch-button .button-circle .switch-after{
    content: '';
    position: absolute;
    top: 2px;
    left: 32px;
    width: 20px;
    height: 22px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 22px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
    
  }
  
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }